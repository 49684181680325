/* global ga, gtag */
var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
$().ready(function(){
	setupSlideMenu();
	if ($("form").length){
		$("form").each(function(){
			$(this).addClass("floatlabel-active").validate();
			$(this).find(".js-float-wrap").FloatLabel();
		});
	}
	$("#map-click").click(function(){
		// shield the map on mobile for easy scrolling
		$(this).hide();
	});
	if ($("header nav.main > ul").is(":visible")){
		$("header nav.main li.depth-0:not(:last-child)").hover(
			function(){$(this).parents("ul").addClass("hovered");},
			function(){$(this).parents("ul").removeClass("hovered");}
		);
		headerScroll();
	}
	if ($(".banners .banner").length > 1){
		scrollBanners();
	}
	if ($("#testimonials div.icon").length > 1){
		testimonialClicker();
	}
	if ($("#form-expand").length > 0){
		quoteToggle();
	}
	//wrap content tables with div to scroll on mobile.
	$("main table").wrap("<div class=\"t-scroll\"></div>");
	var load = false;
	$("a.conversion-click").on("click", function(e){
		if(typeof ga != "undefined"){
			if ($(this).attr("target") != "_blank"){
				e.preventDefault();
				load = $(this).attr("href");
			}
			ga("send", "pageview", "/vpv-brochure-download");
			ga("send", {
				hitType: "event",
				eventCategory: "conversion-click",
				eventAction: $(this).attr("href"),
				hitCallback: function(){
					if (load){
						window.location = load;
					}
				}
			});
		}
		if(typeof gtag != "undefined"){
			gtag("event", "conversion_click", {
				"event_category": "click",
				"event_label": $(this).attr("href")
			});
		}
	});
	// track phone clicks
	$("a[href^='tel:']").on("click", function(e){
		// intercept the click - unless it opens in a new tab
		if ($(this).attr("target") != "_blank"){
			e.preventDefault();
			load = $(this).attr("href");
		}
		if(typeof ga != "undefined"){
			ga("send", {
				hitType: "event",
				eventCategory: "click",
				eventAction: $(this).attr("href"),
				eventLabel: "Call",
				hitCallback: function(){
					// if set - open link after event logged
					if (load){
						window.location = load;
					}
				}
			});
		}
	});
	$(".brochure-download").on("click", function(){
		$(".pipedrive-modal-wrapper").addClass("show");

	});
	$(".pipedrive-modal-wrapper").on("click", function(){
		$(".pipedrive-modal-wrapper").removeClass("show");
	});
});
$(document).on("load", function(){
	setFirstMargin();
});
$(window).on("resize", function(){
	setTimeout(function(){
		setFirstMargin();
		if ($("header nav.main > ul").is(":visible")){
			headerScroll();
		}
		$(".banners").each(function(){ //realign scroller
			var $scroller = $(this).find(".banner-stage");
			if ($scroller.scrollLeft() != 0){
				var pos = $scroller.scrollLeft() + $scroller.find(".banner.active").position().left;
				$scroller.scrollLeft(pos);
			}
		});
	}, 200);
});

export function setupSlideMenu(){
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});
	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}
var rotInt;
export function slideBanner(offset, $scroller, $bnav){
	var $distance = $scroller.innerWidth();
	var $after = $scroller.find(".banner.active").next();

	if (!$scroller.data("animating")){
		$scroller.data("animating", true);
		var newPos;
		if (offset < 0) { //fwd next
			if ($after.length>0){
				newPos = $scroller.scrollLeft() + $distance;
				$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
					$scroller.data("animating", false);
				});
				// set active
				$scroller.find(".banner.active").removeClass("active");
				$after.addClass("active");
				var i= $after.index();
				$bnav.removeClass("active");
				$bnav.eq(i).addClass("active");
			} else { /* you're at the end - go to first */
				var startPos = $scroller.scrollLeft(0);
				$scroller.stop().animate({scrollLeft:startPos},500,"swing", function(){
					$scroller.data("animating", false);
				});
				// set active
				$scroller.find(".banner.active").removeClass("active");
				$scroller.find(".banner").first().addClass("active");
				$bnav.removeClass("active");
				$bnav.eq(0).addClass("active");
			}
		} else { //button index
			var $index = offset;
			var $new = $scroller.find(".banner").eq($index);
			newPos = $scroller.scrollLeft() + $new.position().left;
			$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
				$scroller.data("animating", false);
			});
			clearInterval(rotInt);
			/* set active */
			$bnav.removeClass("active");
			$bnav.eq($index).addClass("active");
			$scroller.find(".banner.active").removeClass("active");
			$new.addClass("active");
			rotInt = setInterval(function(){slideBanner(-1, $scroller, $bnav);}, 5000);
		}
	}
}
export function scrollBanners(){
	$(".banners").each(function(){
		if ($(this).find(".banner").length > 0){
			$(this).find(".banner-navigation").show();
			var $scroller = $(this).find(".banner-stage");
			var $bnav = $(this).find(".banner-nav-item");

			/* auto rotate */
			rotInt = setInterval(function(){slideBanner(-1, $scroller, $bnav);}, 5000);
			/* clicks */
			$bnav.each(function(index, element){
				var $button = $(element);
				$button.on("click touch", function(){
					var index = $(this).index();
					slideBanner(index, $scroller, $bnav);
				});
			});
		}
	});
}

export function checkScrollerSize($wrap){
	var $scroller = $wrap.children("ul");
	if ($scroller.scrollLeft() == 0){
		$wrap.find(".prev").hide();
	} else {
		$wrap.find(".prev").show();
	}
	if ($scroller.scrollLeft() + $scroller.innerWidth() >= $scroller[0].scrollWidth) {
		$wrap.find(".next").hide();
	} else {
		$wrap.find(".next").show();
	}
}
export function slideHeader(offset, $next, $prev, $scroller, $wrapper){
	var $distance = 200;
	if (!$scroller.data("animating")){
		$scroller.data("animating", true);
		var newPos;
		if(offset < 0){ //back
			if ($scroller.scrollLeft() > 0){
				newPos = $scroller.scrollLeft() - $distance;
				$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
					checkScrollerSize($wrapper);
					$scroller.data("animating", false);
				});
			} else $prev.hide();
		} else { //fwd
			if ($scroller.scrollLeft() + $scroller.innerWidth() < $scroller[0].scrollWidth){
				newPos = $scroller.scrollLeft() + $distance;
				$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
					checkScrollerSize($wrapper);
					$scroller.data("animating", false);
				});
			} else $next.hide();
		}
	}
}
export function headerScroll(){
	var $wrap = $(".scroll-wrap");
	$wrap.each(function(index, element) {
		var $wrapper = $(element);
		var $scroller = $wrapper.children("ul");
		if ($scroller[0].scrollWidth > $wrap.innerWidth()){
			var $next = $wrap.find(".next");
			var $prev = $wrap.find(".prev");
			// click functions
			$prev.on("click touch",function(){
				slideHeader(-1, $next, $prev, $scroller, $wrapper);
			});
			$next.on("click touch",function(){
				slideHeader(1, $next, $prev, $scroller, $wrapper);
			});
			checkScrollerSize($wrapper);
		}
	});
}

export function testimonialClicker(){
	$("#testimonials div.icon").click(function(){
		$(this).siblings().removeClass("active");
		$("#testimonials").find(".content").removeClass("active");

		$(this).addClass("active");
		var id = $(this).data("id");
		$("#testimonials div#"+id).addClass("active");
	});
}

export function quoteToggle(){
	$("#form-expand").on("click", function(){
		$(this).parent().toggleClass("active");
	});
}

export function setFirstMargin(){
	if ($(".has-cols div.first").length > 0){
		//get first sub or gallery image
		var firstHeight = 0;
		if ($(".first").find("li").length > 0){ // case-studies else related-subs
			firstHeight = $(".first").find("li").first().outerHeight();
		} else {
			firstHeight = $(".first.gallery").find("a").first().outerHeight();
		}
		$(".first").css("margin-top", "-"+firstHeight+"px");
	}
}
